import React, { createContext, useReducer } from "react";
import { initialState } from "./store/NavbarStore";
import { NavBarReducer } from "./reducer/NavBarReducer";

export const NavBarContext = createContext();

export const NavBarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NavBarReducer, initialState);
  return (
    <NavBarContext.Provider value={{ state, dispatch }}>
      {children}
    </NavBarContext.Provider>
  );
};
