import React from "react";
import Typography from "../../../components/Typography/Typography";
import { useLoader } from "../../../hooks";
import { AdminAnalyticsService } from "../../../services/analyticsService";
import Dropdown from "../../../components/Inputs/Dropdown/indexNoState";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import PieChart from "../../../components/Charts/PieChart";
import Table from "../../../components/Table/Table";
import { UilImport } from "@iconscout/react-unicons";
import classes from "./index.module.css";
import { DownloadAsExcelMultipleSheet } from "../../../utils/xlsx";

const inititalModuleProgressData = {
  totalProgress: 0,
  totalCompletedProgress: 0,
  totalInCompleteProgress: 0,
  totalCompletedProgressPercentage: 0,
  totalInCompleteProgressPercentage: 0,
  completedProgressUsers: [],
  totalInCompleteProgressUsers: [],
  chartData: {
    label: ["Completed", "InComplete"],
    data: [0, 0],
  },
};
const inititalQuizAnaData = {
  totalProgress: 0,
  totalAttemptedQuiz: 0,
  totalDidntAttempt: 0,
  totalAttemptedPercentage: 0,
  totalDidntAttemptpercentage: 0,
  didntAttemptUsers: [],
  attemptedQuizUsers: [],
  chartData: {
    label: ["Attempted", 'Didn"t Attempt'],
    data: [0, 0],
  },
};
const inititalQuizAttemptData = {
  totalAttemptedQuiz: 0,
  totalPassedOnFirstAttempt: 0,
  totalPassedOnMultipleAttempts: 0,
  totalDidntPass: 0,
  totalPassedOnFirstAttemptPercentage: 0,
  totalPassedOnMultipleAttemptsPercentage: 0,
  totalDidntPassPercentage: 0,
  failedQuizAverageScore: 0,
  passedOnFirstAttemptUsers: [],
  passedOnMultipleAttemptsUsers: [],
  didntPassUsers: [],
  chartData: {
    label: [
      "Passed - First Attempt",
      "Passed - Multiple Attempt",
      'Didn"t Pass',
    ],
    data: [0, 0, 0],
  },
};

const AnalyticsPage = () => {
  const [startLoader, stopLoader] = useLoader();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyModuleHashMap, setCompanModuleHashMap] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [moduleOptions, setModuleOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState({});
  const [moduleProgressData, setModuleProgressData] = useState(
    inititalModuleProgressData
  );
  const [quizAnaData, setQuizAnaData] = useState(inititalQuizAnaData);
  const [quizAttemptData, setQuizAttemptData] = useState(
    inititalQuizAttemptData
  );
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    AdminAnalyticsService.GetAnalytics(
      { companyId: selectedCompany.value, moduleId: selectedModule.value },
      () => startLoader("analyticsGet"),
      handleAnalyticsSuccess,
      handleError,
      () => stopLoader("analyticsGet")
    );
  }, [selectedModule]);

  useEffect(() => {
    const modules = companyModuleHashMap[selectedCompany.value] || [];
    const tempOptions = modules.map((m) => ({ label: m.title, value: m.id }));
    setModuleOptions([{ label: "All - Modules", value: "" }, ...tempOptions]);
  }, [selectedCompany]);

  useEffect(() => {
    GetCompaniesWithModules();
  }, []);

  const GetCompaniesWithModules = () => {
    AdminAnalyticsService.GetCompaniesWithModules(
      () => startLoader("GetCompaniesWithModules"),
      handleCompaniesWithModulesSuccess,
      handleError,
      () => stopLoader("GetCompaniesWithModules")
    );
  };

  const handleCompaniesWithModulesSuccess = ({ data }) => {
    const tempCompanyOptions = [{ label: "All - Company", value: "" }];
    const tempCompanyModuleHashMap = {};
    if (Array.isArray(data.data)) {
      data.data.forEach((company) => {
        tempCompanyOptions.push({ label: company.name, value: company.id });
        tempCompanyModuleHashMap[company.id] = company.modules;
      });
      setCompanyOptions(tempCompanyOptions);
      setCompanModuleHashMap(tempCompanyModuleHashMap);
    }
  };

  const handleAnalyticsSuccess = ({ data }) => {
    const {
      moduleProgressData = inititalModuleProgressData,
      quizAnaData = inititalQuizAnaData,
      quizAttemptData = inititalQuizAttemptData,
      totalUsers = 0,
    } = data.data;
    setModuleProgressData(moduleProgressData);
    setQuizAnaData(quizAnaData);
    setQuizAttemptData(quizAttemptData);
    setTotalUsers(totalUsers);
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  const handleDownloadModuleProgressData = () => {
    const sheets = ["Overview", "Completed", "Icomplete"];
    const headers = [
      ["Metric", "Value"],
      ["User Id", "Email"],
      ["User Id", "Email"],
    ];
    const overviewData = [
      {
        a: "Total Users Started",
        b: moduleProgressData.totalProgress,
      },
      {
        a: "Total Completed",
        b: moduleProgressData.totalCompletedProgress,
      },
      {
        a: "Total Incomplete",
        b: moduleProgressData.totalInCompleteProgress,
      },
    ];
    const completedData = moduleProgressData.completedProgressUsers.map(
      (u) => ({ a: u.id, b: u.email })
    );
    const incompletedData = moduleProgressData.totalInCompleteProgressUsers.map(
      (u) => ({ a: u.id, b: u.email })
    );
    const data = [overviewData, completedData, incompletedData];
    DownloadAsExcelMultipleSheet(data, sheets, "Module Progess", headers);
  };

  const handleDownloadQuizAnaData = () => {
    const sheets = ["Overview", "Users Attempted", "Users Unattempted"];
    const headers = [
      ["Metric", "Value"],
      ["User Id", "Email"],
      ["User Id", "Email"],
    ];
    const overviewData = [
      {
        a: "Total Users Started",
        b: quizAnaData.totalProgress,
      },
      {
        a: "Total Users Attempted Quiz",
        b: quizAnaData.totalAttemptedQuiz,
      },
      {
        a: "Total Users Unattempted Quiz",
        b: quizAnaData.totalDidntAttempt,
      },
    ];
    const attemptedData = quizAnaData.attemptedQuizUsers.map((u) => ({
      a: u.id,
      b: u.email,
    }));
    const didntAttemptData = quizAnaData.didntAttemptUsers.map((u) => ({
      a: u.id,
      b: u.email,
    }));
    const data = [overviewData, attemptedData, didntAttemptData];
    DownloadAsExcelMultipleSheet(data, sheets, "Quitz Attempt", headers);
  };

  const handleDownloadQuizAttemptData = () => {
    const sheets = [
      "Overview",
      "Passed (First Attempt)",
      "Passed (Multiple Attempts)",
      "Failed",
    ];
    const headers = [
      ["Metric", "Value"],
      ["User Id", "Email"],
      ["User Id", "Email"],
      ["User Id", "Email"],
    ];
    const overviewData = [
      {
        metric: "Total Attempted Quiz",
        value: quizAttemptData.totalAttemptedQuiz,
      },
      {
        metric: "Total Passed (First Attempt)",
        value: quizAttemptData.totalPassedOnFirstAttempt,
      },
      {
        metric: "Total Passed (Multiple Attempts)",
        value: quizAttemptData.totalPassedOnMultipleAttempts,
      },
      { metric: "Total Failed", value: quizAttemptData.totalDidntPass },
      {
        metric: "Average Failed Percentage",
        value: `${quizAttemptData.failedQuizAverageScore}%`,
      },
    ];
    const firstAttemptData = quizAttemptData.passedOnFirstAttemptUsers.map(
      (u) => ({
        a: u.id,
        b: u.email,
      })
    );
    const multipleAttemptData =
      quizAttemptData.passedOnMultipleAttemptsUsers.map((u) => ({
        a: u.id,
        b: u.email,
      }));
    const failedData = quizAttemptData.didntPassUsers.map((u) => ({
      a: u.id,
      b: u.email,
    }));
    const data = [
      overviewData,
      firstAttemptData,
      multipleAttemptData,
      failedData,
    ];
    DownloadAsExcelMultipleSheet(
      data,
      sheets,
      "Quitz Attempt Break Down",
      headers
    );
  };

  return (
    <div className="rounded-content-area">
      <div className="content-header">
        <div>
          <Typography content={"Analytics"} />
          <div>
            <Dropdown
              options={companyOptions}
              defaultValue={companyOptions[0]}
              onChange={setSelectedCompany}
            />
          </div>
          <div>
            <Dropdown
              options={moduleOptions}
              defaultValue={moduleOptions[0]}
              onChange={setSelectedModule}
            />
          </div>
        </div>
        <div className="table-count">
          Total Users :
          <span>
            <Typography size="14" content={totalUsers} />
          </span>
        </div>
      </div>
      <div className={classes.ChartMain}>
        <div className={classes.ChartParent}>
          <div className={classes.ChartHeadWrapper}>
            <Typography content={"Module Progress Data"} size={12} />
            <div
              className={classes.DnButton}
              onClick={handleDownloadModuleProgressData}
            >
              <UilImport />
            </div>
          </div>
          <div className={classes.ChartWrapper}>
            <div className={classes.ChartChart}>
              <PieChart
                series={moduleProgressData.chartData.data}
                labels={moduleProgressData.chartData.label}
                height={"100%"}
              />
            </div>
          </div>
          {/* <div className={classes.ChartInfo}>
            <div>
              <label>Total Users Started: </label>
              <label>{moduleProgressData.totalProgress}</label>
            </div>
            <div>
              <label>Total Completed: </label>
              <label>{moduleProgressData.totalCompletedProgress}</label>
            </div>
            <div>
              <label>Total Incomplete: </label>
              <label>{moduleProgressData.totalInCompleteProgress}</label>
            </div>
          </div> */}
          <Table
            data={[
              {
                metric: "Total Users Started",
                value: moduleProgressData.totalProgress,
              },
              {
                metric: "Total Completed",
                value: moduleProgressData.totalCompletedProgress,
              },
              {
                metric: "Total Incomplete",
                value: moduleProgressData.totalInCompleteProgress,
              },
            ]}
            head={["Metric", "Value"]}
            keys={["metric", "value"]}
          />
        </div>
        <div className={classes.ChartParent}>
          <div className={classes.ChartHeadWrapper}>
            <Typography content={"Quitz Attempt Data"} size={12} />
            <div
              className={classes.DnButton}
              onClick={handleDownloadQuizAnaData}
            >
              <UilImport />
            </div>
          </div>
          <div className={classes.ChartWrapper}>
            <div className={classes.ChartChart}>
              <PieChart
                series={quizAnaData.chartData.data}
                labels={quizAnaData.chartData.label}
                height={"100%"}
              />
            </div>
          </div>
          {/* <div className={classes.ChartInfo}>
            <div>
              <label>Total Users Started: </label>
              <label>{quizAnaData.totalProgress}</label>
            </div>
            <div>
              <label>Total Users Attempted Quiz: </label>
              <label>{quizAnaData.totalAttemptedQuiz}</label>
            </div>
            <div>
              <label>Total Users Unattempted Quiz: </label>
              <label>{quizAnaData.totalDidntAttempt}</label>
            </div>
          </div> */}
          <Table
            data={[
              {
                metric: "Total Users Started",
                value: quizAnaData.totalProgress,
              },
              {
                metric: "Total Users Attempted Quiz",
                value: quizAnaData.totalAttemptedQuiz,
              },
              {
                metric: "Total Users Unattempted Quiz",
                value: quizAnaData.totalDidntAttempt,
              },
            ]}
            head={["Metric", "Value"]}
            keys={["metric", "value"]}
          />
        </div>
        <div className={classes.ChartParent}>
          <div className={classes.ChartHeadWrapper}>
            <Typography content={"Quitz Attempt Break Down"} size={12} />
            <div
              className={classes.DnButton}
              onClick={handleDownloadQuizAttemptData}
            >
              <UilImport />
            </div>
          </div>
          <div className={classes.ChartWrapper}>
            <div className={classes.ChartChart}>
              <PieChart
                series={quizAttemptData.chartData.data}
                labels={quizAttemptData.chartData.label}
                height={"100%"}
              />
            </div>
          </div>
          {/* <div className={classes.ChartInfo}>
            <div>
              <label>Total Attempted Quiz: </label>
              <label>{quizAttemptData.totalAttemptedQuiz}</label>
            </div>
            <div>
              <label>Total Passed (First Attempt): </label>
              <label>{quizAttemptData.totalPassedOnFirstAttempt}</label>
            </div>
            <div>
              <label>Total Passed (Multiple Attempts): </label>
              <label>{quizAttemptData.totalPassedOnMultipleAttempts}</label>
            </div>
            <div>
              <label>Total Failed: </label>
              <label>{quizAttemptData.totalDidntPass}</label>
            </div>
            <div>
              <label>Average Failed Percentage: </label>
              <label>{quizAttemptData.failedQuizAverageScore} %</label>
            </div>
          </div> */}
          <Table
            data={[
              {
                metric: "Total Attempted Quiz",
                value: quizAttemptData.totalAttemptedQuiz,
              },
              {
                metric: "Total Passed (First Attempt)",
                value: quizAttemptData.totalPassedOnFirstAttempt,
              },
              {
                metric: "Total Passed (Multiple Attempts)",
                value: quizAttemptData.totalPassedOnMultipleAttempts,
              },
              { metric: "Total Failed", value: quizAttemptData.totalDidntPass },
              {
                metric: "Average Failed Percentage",
                value: `${quizAttemptData.failedQuizAverageScore}%`,
              },
            ]}
            head={["Metric", "Value"]}
            keys={["metric", "value"]}
          />
        </div>
      </div>
    </div>
  );
};

export default AnalyticsPage;
