import React, { useEffect, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import Table from "../../../components/Table/Table";
import { AdminCompanyService } from "../../../services/companyService";
import { useLoader } from "../../../hooks/use-loader.hook";
import CreateButton from "../../../components/Buttons/CreateButton";
import ModalComponent from "../../../components/ModalComponent";
import DeleteModal from "../../../components/DeleteModal";
import { toast } from "react-toastify";
import { UilPen } from "@iconscout/react-unicons";
import { UilTrash } from "@iconscout/react-unicons";
import { UilUsersAlt } from "@iconscout/react-unicons";
import CreateEditCompany from "./ModalComponent/CreateEditCompany";
import CreateEditUser from "./ModalComponent/CreateUser";
import { ToggleButtonWithState } from "../../../components/Inputs";

const AllCompanies = ({ companies = [], setRefresh, handleUsersTab }) => {
  const [startLoader, stopLoader] = useLoader();
  const [processedCompanies, setProcessedCompanies] = useState([]);
  const [createUserModal, setCreateuserModal] = useState(false);
  const [createEditModal, setCreateEditModal] = useState({
    status: false,
    editData: {},
  });
  const [deleteModal, setDeleteModal] = useState({
    status: false,
    id: null,
  });

  useEffect(() => {
    processCompanies();
  }, [JSON.stringify(companies)]);

  const processCompanies = () => {
    const processed = companies.map((item, index) => ({
      ...item,
      sno: index + 1,
      access: (
        <div className="action-container">
          <ToggleButtonWithState
            value={item.hasAccess}
            onChange={(e) => handleChangeAccess(item.id, e.target.checked)}
          />
        </div>
      ),
      action: (
        <div className="action-container">
          <div>
            <UilPen
              size={"1.2vw"}
              style={{ color: "var(--color-primary)" }}
              onClick={() => handleCreateEditCompany(true, item)}
            />
          </div>
          <div>
            <UilUsersAlt
              size={"1.2vw"}
              style={{ color: "var(--color-primary)" }}
              onClick={() => handleUsersTab(true, item)}
            />
          </div>
          <div>
            <UilTrash
              size={"1.2vw"}
              style={{ color: "var(--color-primary)" }}
              onClick={() => setDeleteModal({ status: true, id: item.id })}
            />
          </div>
        </div>
      ),
    }));
    setProcessedCompanies(processed);
  };

  const handleChangeAccess = (id, value) => {
    if (id) {
      AdminCompanyService.UpdateAccess(
        id,
        { access: value },
        () => startLoader("access"),
        handleAccessSuccess,
        handleError,
        () => stopLoader("access")
      );
    }
  };

  const handleAccessSuccess = () => {
    setRefresh(Date.now());
    toast.success("Access updated!");
  };

  const handleDelete = () => {
    if (deleteModal.id) {
      AdminCompanyService.Delete(
        deleteModal.id,
        () => startLoader("delete"),
        handleDeleteSuccess,
        handleError,
        () => stopLoader("delete")
      );
    }
  };

  const handleDeleteSuccess = () => {
    setRefresh(Date.now());
    toast.success("Deleted successfully!");
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  const handleCreateEditCompany = (status = true, editData = {}) =>
    setCreateEditModal({ status, editData });

  return (
    <div className="rounded-content-area">
      <div className="content-header">
        <div>
          <Typography content={"All Companies"} />
          <div className="table-count">
            Total Count :
            <span>
              <Typography size="14" content={companies.length} />
            </span>
          </div>
        </div>
        <div className="action-container">
          <CreateButton
            size="medium"
            text="Create Company"
            onClick={handleCreateEditCompany}
          />
          <CreateButton
            size="medium"
            text="Create User"
            onClick={() => setCreateuserModal(true)}
          />
        </div>
      </div>
      <Table
        data={processedCompanies}
        head={["S.No.", "Name", "Access", "Action"]}
        keys={["sno", "name", "access", "action"]}
      />
      <ModalComponent
        isOpen={createEditModal.status}
        setOpen={() => handleCreateEditCompany(false)}
      >
        <CreateEditCompany
          editData={createEditModal.editData}
          setOpen={handleCreateEditCompany}
          setRefresh={setRefresh}
        />
      </ModalComponent>
      <ModalComponent
        isOpen={createUserModal}
        setOpen={() => setCreateuserModal(false)}
      >
        <CreateEditUser setOpen={setCreateuserModal} companies={companies} />
      </ModalComponent>
      <DeleteModal
        opendeleteModal={deleteModal.status}
        setOpenDeleteModal={(status) => setDeleteModal({ status, id: null })}
        deletefunction={handleDelete}
        functionParam={deleteModal.id}
        text="Deleting company will also delete its users"
      ></DeleteModal>
    </div>
  );
};

export default AllCompanies;
