import { useState } from "react";
import classes from "./index.module.css";
import { useEffect } from "react";
import { useLoader } from "../../../hooks";
import { AdminModuleService } from "../../../services/moduleService";
import { toast } from "react-toastify";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { UilTrash, UilPlay } from "@iconscout/react-unicons";
import { FieldArray, Form, Formik } from "formik";
import { Input } from "../../../components/Inputs";
import CreateButton from "../../../components/Buttons/CreateButton";
import FileUpload from "../../../components/Inputs/FileUpload";
import { EditLecturesValidation } from "../../../validations/Admin/Module";
import VideoJS from "../../../components/VideoPlayer";
import Typography from "../../../components/Typography/Typography";

const getInitialValues = (lectures = []) => {
  const initialValues = { lectures: [] };
  lectures.map((item) =>
    initialValues.lectures.push({
      id: item.id,
      title: item.title,
      videoUrl: item.videoUrl,
    })
  );
  return initialValues;
};

const videoPlayerOptions = {
  autoplay: true,
  controls: true,
  responsive: true,
  fluid: true,
};

const EditLectures = ({ moduleId }) => {
  const [startLoader, stopLoader] = useLoader();
  const [refresh, setRefresh] = useState(null);
  const [moduleInfo, setModuleInfo] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [playVideoUrl, setPlayVideoUrl] = useState(null);

  useEffect(() => {
    getModuleInfo();
  }, [refresh]);

  const getModuleInfo = () => {
    if (moduleId) {
      AdminModuleService.Info(
        moduleId,
        () => startLoader("getAll"),
        handleSuccess,
        handleError,
        () => stopLoader("getAll")
      );
    }
  };

  const handleSuccess = ({ data }) => {
    if (data.data) {
      const { videoLectures = [] } = data.data;
      setModuleInfo(data.data);
      if (videoLectures.length) {
        setInitialValues(getInitialValues(videoLectures));
      }
    }
  };

  const handleVideoUpload = (setFieldValue, values, index) => {
    const file = values.lectures[index].file;
    if (file?.name) {
      const validFormat = /(mp4)$/.test(file.name.slice(-4));
      if (validFormat) {
        const params = { fileName: file.name, fileType: file.type };
        setFieldValue(`lectures.${index}.error`, "");
        setFieldValue(`lectures.${index}.uploadStatus`, "WAITING");
        AdminModuleService.UploadUrl(
          params,
          () => startLoader(`getUrl-${index}`),
          (res) => handleUrlSuccess(res, setFieldValue, index, values),
          (err) => handleUploadError(err, index, setFieldValue),
          () => stopLoader(`getUrl-${index}`)
        );
      } else {
        setFieldValue(
          `lectures.${index}.error`,
          "Unsupport video format, Supported format: .mp4"
        );
      }
    } else {
      setFieldValue(`lectures.${index}.error`, "Please select a file first");
    }
  };

  const handleUrlSuccess = ({ data }, setFieldValue, index, values) => {
    if (data.data) {
      AdminModuleService.UploadFileToS3(
        data.data.uploadUrl,
        values.lectures[index].file,
        (e) =>
          handleUploadProgress(e, setFieldValue, index, data.data.location),
        (err) => handleUploadError(err, index, setFieldValue)
      );
    }
  };

  const handleUploadProgress = (e, setFieldValue, index, location) => {
    const percentageValue = Number(Number(e.progress * 100).toFixed(2));

    setFieldValue(`lectures.${index}.uploadProgress`, percentageValue);
    if (percentageValue === 100) {
      setFieldValue(`lectures.${index}.uploadStatus`, "UPLOADED");
      setFieldValue(`lectures.${index}.newVideoUrl`, location);
    } else {
      setFieldValue(`lectures.${index}.uploadStatus`, "UPLOADING");
    }
  };

  const handleUploadError = (err, index, setFieldValue) => {
    setFieldValue(`lectures.${index}.uploadStatus`, "ERRORED");
  };

  const handleSubmit = (values) => {
    if (moduleId) {
      AdminModuleService.UpdateLectures(
        moduleId,
        values,
        () => startLoader("updateLectures"),
        handleUpdateLectureSuccess,
        handleError,
        () => stopLoader("updateLectures")
      );
    }
  };

  const handleUpdateLectureSuccess = ({}) => {
    toast.success("Lectures Updated");
    setRefresh(Date.now());
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  return (
    <div className="rounded-content-area">
      <div className={classes.EditMain}>
        <div className={classes.EditFirst}>
          <div className={classes.FisrtEmpty}>
            <Typography content={"Edit Lectures"} />
          </div>
          <div className={classes.FisrtWrapper}>
            {playVideoUrl ? (
              <div className={classes.EditImageContainer}>
                <VideoJS
                  options={{
                    ...videoPlayerOptions,
                    sources: [{ src: playVideoUrl, type: "video/mp4" }],
                  }}
                />
              </div>
            ) : (
              <div className={classes.EditImageContainer}>
                <img
                  src={moduleInfo.thumbnail}
                  className={classes.EditImage}
                  alt="thumbnail"
                />
              </div>
            )}

            <div className={classes.EditInfo}>
              <div className={classes.EditTitle}>{moduleInfo.title}</div>
              <div className={classes.EditDescription}>
                {moduleInfo.description}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.EditSecond}>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={EditLecturesValidation}
            enableReinitialize
            className={classes.FromContainer}
          >
            {({ errors, values, setFieldValue }) => (
              <Form>
                <div
                  className={classes.CButtonsContainer + " buttons-container"}
                >
                  <div>
                    <button className="btn-primary" type="submit">
                      Save Changes
                    </button>
                  </div>
                </div>
                <Accordion
                  className={classes.AccordContainer}
                  allowMultipleExpanded
                  allowZeroExpanded
                >
                  <FieldArray name="lectures">
                    {({ push, remove, form }) => {
                      const { lectures: vLectures = [] } = form.values;
                      return (
                        <div>
                          <div className={classes.LecturesContainer}>
                            {vLectures.map((item, index) => (
                              <AccordionItem className={classes.AccordItem}>
                                <AccordionItemHeading>
                                  <AccordionItemButton
                                    className={classes.AccordButton}
                                  >
                                    <div>
                                      {index + 1}.{" "}
                                      {item.title
                                        ? item.title
                                        : "-- No title added --"}
                                    </div>
                                    <div className="action-container">
                                      {errors?.lectures?.[index] && (
                                        <div className={classes.ErrorPreview}>
                                          ERROR
                                        </div>
                                      )}

                                      {item.videoUrl && (
                                        <UilPlay
                                          size={"1.5vw"}
                                          style={{ color: "#14ae39" }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setPlayVideoUrl(item.videoUrl);
                                          }}
                                        />
                                      )}

                                      <UilTrash
                                        size={"1.5vw"}
                                        style={{ color: "#e74c3c" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          remove(index);
                                        }}
                                      />
                                    </div>
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <div className="input-container">
                                    <div className="field-container-full-width">
                                      <label htmlFor="title">Title</label>
                                      <Input name={`lectures.${index}.title`} />
                                    </div>
                                  </div>
                                  <div className="input-container">
                                    <div className="field-container-full-width">
                                      <label htmlFor={`lectures.${index}.urlT`}>
                                        Video - Supported Fromat: .mp4
                                      </label>
                                      <FileUpload
                                        name={`lectures.${index}.urlT`}
                                        accept="video/*"
                                        onChange={(e) =>
                                          setFieldValue(
                                            `lectures.${index}.file`,
                                            e.target.files[0]
                                          )
                                        }
                                        id={`lectures.${index}.file`}
                                      />
                                      <div className="error-msg">
                                        {errors?.lectures?.[index]?.newVideoUrl
                                          ? errors?.lectures?.[index]
                                              ?.newVideoUrl +
                                            ", upload video to resolve error"
                                          : ""}
                                      </div>
                                      <div className="error-msg">
                                        {item.error}
                                      </div>
                                      <div className={classes.UploadStatus}>
                                        {item.uploadStatus && (
                                          <>
                                            Upload Staus:{" "}
                                            {item.uploadStatus ===
                                              "WAITING" && (
                                              <span
                                                className={
                                                  classes.UploadWaiting
                                                }
                                              >
                                                Waiting
                                              </span>
                                            )}
                                            {item.uploadStatus ===
                                              "UPLOADING" && (
                                              <span
                                                className={
                                                  classes.UploadUploading
                                                }
                                              >
                                                Uploading: {item.uploadProgress}{" "}
                                                %
                                              </span>
                                            )}
                                            {item.uploadStatus ===
                                              "UPLOADED" && (
                                              <span
                                                className={
                                                  classes.UploadSuccess
                                                }
                                              >
                                                Uploaded
                                              </span>
                                            )}
                                            {item.UploadError === "ERRORED" && (
                                              <span
                                                className={
                                                  classes.UploadWaiting
                                                }
                                              >
                                                Error Occured
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </div>
                                      <div className="buttons-container">
                                        <div>
                                          <button
                                            type="button"
                                            className="btn-primary"
                                            onClick={() =>
                                              handleVideoUpload(
                                                setFieldValue,
                                                values,
                                                index
                                              )
                                            }
                                          >
                                            Upload
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </AccordionItemPanel>
                              </AccordionItem>
                            ))}
                          </div>
                          <div className={classes.AccordActionButton}>
                            <CreateButton
                              size={"medium"}
                              text="Add Lecture"
                              onClick={() => push({ title: "", videoUrl: "" })}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                </Accordion>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditLectures;
