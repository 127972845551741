import Styles from "./Checkbox.module.css";
import { useField } from "formik";

const Checkbox = ({
  name,
  label,
  defaultValue,
  showError = true,
  ...props
}) => {
  const [, meta, helpers] = useField(name);

  const onChange = (e) => {
    helpers.setValue && helpers.setValue(e.target.checked);
    props.onChange && props.onChange(e.target.checked);
  };

  return (
    <div className={Styles.checkboxWrapper}>
      <div className={Styles.inputWrapper}>
        <input
          type="checkbox"
          className={Styles.input}
          checked={defaultValue}
          onClick={onChange}
        />
        <span className={Styles.checkmark}></span>
      </div>
      {label ? (
        <label htmlFor={name} className={Styles.label}>
          {label}
        </label>
      ) : null}
      {showError && meta.touched && meta.error ? (
        <div className="error-msg">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Checkbox;
