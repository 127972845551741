import React, { useEffect, useContext, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { get as lodashGet } from "lodash";
import Sidebar from "../components/Sidebar/Sidebar";
import MainContainer from "../containers/MainContainer/MainContainer";
import { AuthContext } from "../context/AuthContext";
import { PROTECTED_ROUTES, PROTECTED_ROUTES_ADMIN } from "../routes/Routes";
import { USER_ROLE } from "../constants";

const getActiveRoute = (userRole) => {
  const processedRoutes =
    userRole === "admin"
      ? PROTECTED_ROUTES_ADMIN
      : userRole === "user"
      ? PROTECTED_ROUTES
      : [];

  const curRoute = processedRoutes
    .slice()
    .reverse()
    .find((route) => window.location.pathname.includes(route.url));
  if (curRoute?.childRoutes) {
    const curSubRoute = curRoute.childRoutes.find((route) =>
      window.location.pathname.includes(route.url)
    );
    if (curSubRoute) return `${curRoute?.name} > ${curSubRoute.name}`;
  } else {
    return curRoute?.name;
  }
};

const PublicLayout = () => {
  const history = useHistory();
  const { state } = useContext(AuthContext);
  const userRole = lodashGet(state, "user.userRole");
  const [activeRoute, setActiveRoute] = useState(getActiveRoute(userRole));

  useEffect(() => {
    setActiveRoute(getActiveRoute(userRole));
  }, [userRole]);

  useEffect(() => {
    history.listen(() => {
      window.scrollTo(0, 0);
    });
  });

  return (
    <div className="main-content">
      {[USER_ROLE.USER].includes(userRole) && <></>}
      {[USER_ROLE.ADMIN].includes(userRole) && (
        <div>
          <Sidebar active={activeRoute} setActive={setActiveRoute} />
        </div>
      )}

      <div style={{ height: "100vh", overflowY: "auto", flexGrow: 1, overflowX: 'hidden' }}>
        <Switch>
          <Route
            path={"/"}
            render={() => <MainContainer title={activeRoute} />}
          />
        </Switch>
      </div>
    </div>
  );
};

export default PublicLayout;
