import React, { useState } from "react";
import classes from "./index.module.css";
import CompanyLogo from "../../assets/arielLogo.png";
import { addAlpha } from "../../utils/cssHelper";
import { UilBars, UilTimes } from "@iconscout/react-unicons";
import { REMOVE_USER } from "../../constants";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useHistory, Link } from "react-router-dom";
import { NavBarContext } from "../../context/NavBarContext";
import { UilAngleLeftB } from "@iconscout/react-unicons";

const getOverlayBgColor = () => {
  const hexCode =
    document.documentElement.style.getPropertyValue("--color-primary");
  return addAlpha(hexCode, 0.9);
};

const Navbar = () => {
  const { dispatch: authDispatch } = useContext(AuthContext);
  const { state: NavBarState } = useContext(NavBarContext);
  const history = useHistory();
  const [burger, setBurger] = useState(false);

  const handleLogout = () => {
    toast.success("Logout Success!");
    authDispatch({ type: REMOVE_USER });
    history.push("/auth/login");
  };

  return (
    <div className={classes.NavContainer}>
      <div className={classes.Nav}>
        {NavBarState.showDefault ? (
          <>
            <input
              type="checkbox"
              checked={burger}
              className={classes.NavCheck}
            />
            <div className={classes.NavHeader}>
              <div className={classes.NavTitle}>
                <img
                  src={CompanyLogo}
                  alt="logo"
                  className={classes.CompanyLogo}
                />
              </div>
            </div>
            <div className={classes.NavBtn} onClick={(e) => setBurger(!burger)}>
              {burger ? (
                <UilTimes className={classes.ToggleIcon} />
              ) : (
                <UilBars className={classes.ToggleIcon} />
              )}
            </div>

            <div
              className={classes.NavLinks}
              style={{ backgroundColor: getOverlayBgColor() }}
            >
              <Link to="/" onClick={() => setBurger(!burger)}>
                Home
              </Link>
              <Link to="/profile" onClick={() => setBurger(!burger)}>
                Profile
              </Link>
              <a onClick={handleLogout}>Logout</a>
            </div>
          </>
        ) : (
          <div className={classes.Nav2}>
            <div className={classes.Nav2BackContainer} onClick={history.goBack}>
              <UilAngleLeftB />
            </div>
            <div className={classes.Nav2Title}>{NavBarState.title}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
