// icons
// import UserIcon from "../assets/sidebar/user.svg";
import CompanyIcon from "../assets/sidebar/company.svg";
import ModuleIcon from "../assets/sidebar/module.svg";
import AnalyticsIcon from "../assets/sidebar/analytics.svg";

// components
import Company from "../containers/Admin/Company";
import Modules from "../containers/Admin/Module";
import Home from "../containers/User/Home";
import Module from "../containers/User/Module";
import EditProfile from '../containers/User/Home/UpdateProfile'
import Analytics from "../containers/Admin/Analytics";

export const PROTECTED_ROUTES = [
  {
    name: "Home",
    id: "home",
    url: "/",
    component: Home,
    icon: CompanyIcon,
  },
  {
    name: "Module Progress",
    id: "module",
    url: "/module",
    params: "/:id",
    component: Module,
    icon: CompanyIcon,
  },
  {
    name: "Profile",
    id: "profile",
    url: "/profile",
    component: EditProfile,
    icon: CompanyIcon,
  },
];

export const PROTECTED_ROUTES_ADMIN = [
  {
    name: "Company",
    id: "company",
    url: "/",
    component: Company,
    icon: CompanyIcon,
  },
  {
    name: "Module",
    id: "module",
    url: "/module",
    component: Modules,
    icon: ModuleIcon,
  },
  {
    name: "Analytics",
    id: "analytics",
    url: "/analytics",
    component: Analytics,
    icon: AnalyticsIcon,
  },
];
