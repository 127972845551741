import React from "react";
import TabsComponent from "../../../components/Tabs/Tabs";
import AnalyticsPage from "./Analytics";

const Analytics = () => {
  const tabs = [
    {
      name: "Analytics",
      component: <AnalyticsPage />,
    },
  ];

  return (
    <div className="react-tabs-background">
      <TabsComponent tabs={tabs} />
    </div>
  );
};

export default Analytics;
