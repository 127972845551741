import React, { useEffect, useState } from "react";
import Typography from "../../../components/Typography/Typography";
import CreateButton from "../../../components/Buttons/CreateButton";
import ModalComponent from "../../../components/ModalComponent";
import { toast } from "react-toastify";
import { AdminModuleService } from "../../../services/moduleService";
import { useLoader } from "../../../hooks";
import { ToggleButtonWithState } from "../../../components/Inputs";
import { UilPen } from "@iconscout/react-unicons";
import { UilTrash } from "@iconscout/react-unicons";
import { UilEllipsisV } from "@iconscout/react-unicons";
import { UilCheckCircle } from "@iconscout/react-unicons";
import { UilTimesCircle } from "@iconscout/react-unicons";
import { UilUserCheck } from "@iconscout/react-unicons";
import classes from "./index.module.css";
import CreateEditModule from "./ModalComponent/CreateEditModule";
import DeleteModal from "../../../components/DeleteModal";
import { Menu, MenuItem } from "@szhsin/react-menu";
import AccessModule from "./ModalComponent/AccessModule";

const AllModules = ({
  handleEditLecturesTab,
  handleEditQuizTab,
  handleUserProgressTab,
}) => {
  const [startLoader, stopLoader] = useLoader();
  const [refresh, setRefresh] = useState(null);
  const [modules, setModules] = useState([]);
  const [createEditModal, setCreateEditModal] = useState({
    status: false,
    editData: {},
  });
  const [deleteModal, setDeleteModal] = useState({
    status: false,
    id: null,
  });
  const [hoverEleIndex, setHoverEleIndex] = useState(null);
  const [accessModal, setAccessModal] = useState({ status: false, id: null });

  useEffect(() => {
    getData();
  }, [refresh]);

  const getData = () => {
    AdminModuleService.GetAll(
      {},
      () => startLoader("getAll"),
      handleSuccess,
      handleError,
      () => stopLoader("getAll")
    );
  };

  const handleSuccess = ({ data }) => {
    if (Array.isArray(data.data)) {
      const processed = data.data.map((item, index) => ({
        ...item,
        sno: index + 1,
        rThumnail: (
          <img
            src={item.thumbnail}
            alt="thumbnail"
            className={classes.Thumbnail}
          />
        ),
        published: (
          <div className="action-container">
            <ToggleButtonWithState
              value={item.isPublished}
              onChange={(e) => handleChangePublish(item.id, e.target.checked)}
            />
          </div>
        ),
        action: (
          <div className={classes.ActionContainerNew}>
            <div className="action-container">
              <UilPen
                size={"1.2vw"}
                style={{ color: "var(--color-primary)" }}
                onClick={() => handleCreateEditModule(true, item)}
              />
            </div>
            <div className="action-container">
              <UilTrash
                size={"1.2vw"}
                style={{ color: "var(--color-primary)" }}
                onClick={() => setDeleteModal({ status: true, id: item.id })}
              />
            </div>
            <div className="action-container">
              <UilUserCheck
                size={"1.2vw"}
                style={{ color: "var(--color-primary)" }}
                onClick={() => setAccessModal({ status: true, id: item.id })}
              />
            </div>
            <Menu
              menuButton={
                <div className="action-container">
                  <UilEllipsisV
                    size={"1.2vw"}
                    style={{ color: "var(--color-primary)" }}
                  />
                </div>
              }
              align="end"
              arrow={true}
              menuClassName="custom-menu"
            >
              <MenuItem
                className="custom-menu-item"
                onClick={() => handleChangePublish(item.id, !item.isPublished)}
              >
                {item.isPublished ? "Unpublish" : "Publish"}
              </MenuItem>
              <MenuItem
                className="custom-menu-item"
                onClick={() => handleEditLecturesTab(true, item.id)}
              >
                Edit Lectures
              </MenuItem>
              <MenuItem
                className="custom-menu-item"
                onClick={() => handleEditQuizTab(true, item.id)}
              >
                Edit Quiz
              </MenuItem>
              <MenuItem
                className="custom-menu-item"
                onClick={() =>
                  handleUserProgressTab(
                    true,
                    item.id,
                    item.videoLectures?.length
                  )
                }
              >
                Users Progress
              </MenuItem>
            </Menu>
          </div>
        ),
      }));
      setModules(processed);
    }
  };

  const handleDelete = () => {
    if (deleteModal.id) {
      AdminModuleService.Delete(
        deleteModal.id,
        () => startLoader("delete"),
        handleDeleteSuccess,
        handleError,
        () => stopLoader("delete")
      );
    }
  };

  const handleDeleteSuccess = () => {
    setRefresh(Date.now());
    toast.success("Deleted successfully!");
  };

  const handleChangePublish = (id, value) => {
    if (id) {
      AdminModuleService.UpdatePublish(
        id,
        { publish: value },
        () => startLoader("access"),
        handlePublishSuccess,
        handleError,
        () => stopLoader("access")
      );
    }
  };

  const handlePublishSuccess = () => {
    setRefresh(Date.now());
    toast.success("Status updated!");
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  const handleCreateEditModule = (status = true, editData = {}) =>
    setCreateEditModal({ status, editData });

  return (
    <div className="rounded-content-area">
      <div className="content-header">
        <div>
          <Typography content={"All Modules"} />
          <div className="table-count">
            Total Count :
            <span>
              <Typography size="14" content={modules.length} />
            </span>
          </div>
        </div>
        <div className="action-container">
          <CreateButton
            size="medium"
            text="Create Module"
            onClick={handleCreateEditModule}
          />
        </div>
      </div>
      <div className={classes.ModulesWrapper}>
        {modules.map((item, index) => (
          <div
            className={classes.ModuleContainer}
            onMouseOver={() => setHoverEleIndex(index)}
            onMouseLeave={() => setHoverEleIndex(null)}
          >
            <div className={classes.ModuleImageWrapper}>
              <img
                src={item.thumbnail}
                alt="thumbnail"
                className={classes.ModuleImage}
              />
            </div>
            <div className={classes.ModuleInfo}>
              <div className={classes.ModuleTitle}>{item.title}</div>
              <div className="action-container">
                {item.isPublished ? (
                  <UilCheckCircle size={"1.5vw"} style={{ color: "#14ae39" }} />
                ) : (
                  <UilTimesCircle size={"1.5vw"} style={{ color: "#e74c3c" }} />
                )}
              </div>
            </div>
            <div
              className={
                hoverEleIndex === index
                  ? classes.ActionContainer
                  : classes.ActionContainerNo
              }
            >
              <div>{item.action}</div>
            </div>
          </div>
        ))}
      </div>
      <ModalComponent
        isOpen={createEditModal.status}
        setOpen={() => handleCreateEditModule(false)}
      >
        <CreateEditModule
          setOpen={handleCreateEditModule}
          setRefresh={setRefresh}
          editData={createEditModal.editData}
        />
      </ModalComponent>
      <ModalComponent
        isOpen={accessModal.status}
        setOpen={() => setAccessModal({ status: false, id: null })}
      >
        <AccessModule moduleId={accessModal.id} />
      </ModalComponent>
      <DeleteModal
        opendeleteModal={deleteModal.status}
        setOpenDeleteModal={(status) => setDeleteModal({ status, id: null })}
        deletefunction={handleDelete}
        functionParam={deleteModal.id}
        text="Deleting module will also delete lectures"
      ></DeleteModal>
    </div>
  );
};

export default AllModules;
