import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLoader } from "../../../hooks";
import Typography from "../../../components/Typography/Typography";
import Table from "../../../components/Table/Table";
import { AdminModuleService } from "../../../services/moduleService";
import moment from "moment/moment";
import classes from "./index.module.css";
import ModalComponent from "../../../components/ModalComponent";
import QuizAttemptsModule from "./ModalComponent/QuizAttemptsModule";
import { DownloadAsExcel } from "../../../utils/xlsx";

const UserProgress = ({ moduleId, totalLectures }) => {
  const [startLoader, stopLoader] = useLoader();
  const [data, setData] = useState([]);
  const [quizDetailsModal, setQuizDetailsModal] = useState({
    status: false,
    attempts: [],
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    AdminModuleService.GetUsersProgress(
      moduleId,
      {},
      () => startLoader("getProgress"),
      handleSuccess,
      handleError,
      () => stopLoader("getProgress")
    );
  };

  const handleSuccess = ({ data }) => {
    if (Array.isArray(data.data)) {
      const processed = data.data.map((item) => ({
        ...item,
        isComplete: item.isComplete ? "Yes" : "No",
        userEmail: item.userEmail ? item.userEmail : "--",
        userName: item.userName ? item.userName : "--",
        completedAt: item.completedAt
          ? moment(item.completedAt).format()
          : "--",
        quizAttemptsT: (
          <div
            className={classes.TableLinkCell}
            onClick={() =>
              setQuizDetailsModal({
                status: true,
                attempts: item.quizAttemptsData,
              })
            }
          >
            {item.quizAttempts} Time(s)
          </div>
        ),
        quizPassed: item.quizPassed ? "Yes" : "No",
        certificateO: item.certificate,
        certificate: item.certificate ? (
          <div
            className={classes.TableLinkCell}
            onClick={() => window.open(item.certificate, "_blank").focus()}
          >
            View
          </div>
        ) : (
          "N/A"
        ),
      }));
      setData(processed);
    }
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  const handleDownload = () => {
    const report = data.map((d) => ({
      a: d.userId,
      b: d.userName,
      c: d.userEmail,
      d: d.company,
      e: d.isComplete,
      f: d.completedAt,
      g: d.lecturesCompleted,
      h: d.quizAttempts,
      i: d.quizPassed,
      j: d.certificateO,
      k: d.certificateId,
    }));
    DownloadAsExcel(report, "Users Progress", [
      "User Id",
      "User Name",
      "User Email",
      "Company",
      "Has Completed",
      "Completed At",
      "Lectures Completed",
      "No. Quiz Attempts",
      "Quiz Passed",
      "Certificate",
      "Certificate Id",
    ]);
  };

  return (
    <div className="rounded-content-area">
      <div className="content-header">
        <div>
          <Typography content={"Users Progress"} />
          <div className="table-count">
            Total Count :
            <span>
              <Typography size="14" content={data.length} />
            </span>
          </div>
          <div className="table-count">
            Total Video Lectures :
            <span>
              <Typography size="14" content={totalLectures} />
            </span>
          </div>
        </div>
        <div className="content-header">
          <div></div>
          <button
            className={`btn-primary ${classes.Button}`}
            onClick={handleDownload}
          >
            Download
          </button>
        </div>
      </div>
      <Table
        data={data}
        head={[
          "User Name",
          "User Email",
          "Company",
          "Has Completed",
          "Completed At",
          "Lectures Completed",
          "Quiz Attempts",
          "Quiz Passed",
          "Certificate",
        ]}
        keys={[
          "userName",
          "userEmail",
          "company",
          "isComplete",
          "completedAt",
          "lecturesCompleted",
          "quizAttemptsT",
          "quizPassed",
          "certificate",
        ]}
      />
      <ModalComponent
        isOpen={quizDetailsModal.status}
        setOpen={() => setQuizDetailsModal({ status: false, id: null })}
      >
        <QuizAttemptsModule
          attempts={quizDetailsModal.attempts}
          moduleId={moduleId}
        />
      </ModalComponent>
    </div>
  );
};

export default UserProgress;
