import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext } from "react";
import PhoneNumberComponent from "../../../components/Inputs/PhoneNumberComponent";
import { AuthContext } from "../../../context/AuthContext";
import classes from "../../../UserClasses.module.css";
import Typography from "../../../components/Typography/Typography";
import Select from "react-select";
import { useLoader } from "../../../hooks";
import { COUNTRY_CODES, SET_USER } from "../../../constants";
import {
  UpdatePasswordValidation,
  UpdateProfileValidation,
} from "../../../validations/Admin/User";
import { UserService } from "../../../services/userService";
import { toast } from "react-toastify";

const getCountryCodeDropdownOptions = () => {
  const options = COUNTRY_CODES.map((detail) => ({
    label: `${detail.dial_code} ${detail.name}`,
    value: detail.dial_code,
  }));
  return options;
};

const UpdateProfile = () => {
  return (
    <div className={classes.UWrapper1}>
      <div className={classes.UWrapper2 + " rounded-content-area"}>
        <UpdateProfileDetails />
      </div>
    </div>
  );
};

export default UpdateProfile;

const customStyles = {
  option: (base, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...base,
      backgroundColor:
        isFocused || isSelected ? "var(--color-secondary)" : "#ffffff",
      color: "black",
    };
  },
  control: (base, { isFocused }) => ({
    ...base,
    border: isFocused ? "2px solid black" : "",
    boxShadow: "none",
    "&:hover": {
      border: "2px solid black",
    },
  }),
};

const UpdateProfileDetails = () => {
  const { state } = useContext(AuthContext);
  const [startLoader, stopLoader] = useLoader();
  const countryCodeOptions = getCountryCodeDropdownOptions();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const dispatchSetUser = (payload) =>
    authDispatch({ type: SET_USER, payload });

  const initialValues = {
    name: state.user?.name,
    email: state.user?.email,
    contact: {
      countryCode: state.user?.contact?.countryCode,
      phone: state.user?.contact?.phone,
    },
  };

  const updateProfile = (values) => {
    UserService.UpdateProfile(
      values,
      () => startLoader("update-profile"),
      handleUpdateProfileSuccess,
      handleError,
      () => stopLoader("update-profile")
    );
  };

  const handleUpdateProfileSuccess = () => {
    toast.success("Profile updated successfully!");
    verifyUser();
  };

  const verifyUser = () => {
    UserService.Profile(
      () => startLoader("verifyUser"),
      handleVerifySuccess,
      handleError,
      () => stopLoader("verifyUser")
    );
  };

  const handleVerifySuccess = ({ data }) => {
    if (data.success) {
      dispatchSetUser({ user: data.data });
    }
  };

  const updatePassword = (values) => {
    UserService.UpdatePassword(
      values,
      () => startLoader("update-password"),
      handleUpdatePasswordSuccess,
      handleError,
      () => stopLoader("update-password")
    );
  };

  const handleUpdatePasswordSuccess = () => {
    toast.success("Password updated successfully!");
  };

  const handleError = (err) => {
    let data = err && err.response ? err.response.data : null;
    if (data) toast.error(data.message);
    else toast.error("Internal server error!");
  };

  return (
    <div>
      <div className={classes.UFormTitle}>Update Profile</div>
      <Formik
        onSubmit={updateProfile}
        validationSchema={UpdateProfileValidation}
        initialValues={initialValues}
      >
        {({ errors, touched, values, isValidating, ...props }) => (
          <Form className={classes.UForm}>
            <div className="input-container">
              <label htmlFor="name">Name</label>
              <Field name="name" />
            </div>
            <ErrorMessage
              name="name"
              className={classes.ErrorMsg}
              component={"div"}
            />
            <div className="input-container">
              <label htmlFor="email">Email</label>
              <Field name="email" />
            </div>
            <ErrorMessage
              name="email"
              className={classes.ErrorMsg}
              component={"div"}
            />
            <div className="input-container">
              <label htmlFor="contact.countryCode">Country Code</label>
              <Select
                options={countryCodeOptions}
                className={classes.USelect}
                value={countryCodeOptions.filter(
                  (v) => v.value === values.contact.countryCode
                )}
                onChange={(e) =>
                  props.setFieldValue("contact.countryCode", e.value)
                }
                styles={customStyles}
              />
            </div>
            <ErrorMessage
              name="contact.countryCode"
              className={classes.ErrorMsg}
              component={"div"}
            />
            <div className="input-container">
              <label htmlFor="contact.phone">Phone</label>
              <Field name="contact.phone" type="number" />
            </div>
            <ErrorMessage
              name="contact.phone"
              className={classes.ErrorMsg}
              component={"div"}
            />
            <div>
              <button type="submit" className={classes.Button}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <div className={classes.Divider}></div>

      <div className={classes.UFormTitle}>Update Password</div>
      <Formik
        onSubmit={updatePassword}
        validationSchema={UpdatePasswordValidation}
        initialValues={{ curPassword: "", password: "", confirmPassowrd: "" }}
      >
        {({ errors, touched, values, isValidating, ...props }) => (
          <Form className={classes.UForm}>
            <div className="input-container">
              <label htmlFor="curPassword">Current Password</label>
              <Field name="curPassword" />
            </div>
            <ErrorMessage
              name="curPassword"
              className={classes.ErrorMsg}
              component={"div"}
            />
            <div className="input-container">
              <label htmlFor="password">New password</label>
              <Field name="password" />
            </div>
            <ErrorMessage
              name="password"
              className={classes.ErrorMsg}
              component={"div"}
            />
            <div className="input-container">
              <label htmlFor="confirmPassowrd">Confirm New Password</label>
              <Field name="confirmPassowrd" />
            </div>
            <ErrorMessage
              name="confirmPassowrd"
              className={classes.ErrorMsg}
              component={"div"}
            />
            <div>
              <button type="submit" className={classes.Button}>
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
