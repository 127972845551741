import * as yup from "yup";
import { ERRORS } from "../../utils/validationHelper";
import _ from "lodash";

export const CreateModuleValidation = yup.object().shape({
  title: yup.string().required(ERRORS.required),
  description: yup.string().required(ERRORS.required),
});

export const EnableCompanyValidation = yup.object().shape({
  companyIds: yup.array().min(1).required(ERRORS.required),
});

export const EnableUsersValidation = yup.object().shape({
  userIds: yup.array().min(1).required(ERRORS.required),
});


export const EditLecturesValidation = yup.object().shape({
  lectures: yup
    .array()
    .of(
      yup.object().shape({
        title: yup.string().required(ERRORS.required),
        videoUrl: yup.string().url(ERRORS.url).optional(),
        newVideoUrl: yup
          .string()
          .url(ERRORS.url)
          .test(
            "new-url",
            "This is a required field",
            function (value, context) {
              if (context.parent.videoUrl) {
                return true;
              } else if (value) {
                return true;
              } else return false;
            }
          ),
      })
    )
    .required(yup.string().url()),
});

export const EditQuizValidation = yup.object().shape({
  id: yup.string(),
  title: yup.string().required(ERRORS.required),
  questions: yup
    .array()
    .of(
      yup.object().shape({
        question: yup.string().required(ERRORS.required),
        options: yup
          .array()
          .of(
            yup.object().shape({
              answer: yup.string().required(ERRORS.required),
              isCorrect: yup.boolean().required(ERRORS.required),
            })
          )
          .test(
            "one-true-yy",
            "At lease one answer should be checked",
            (val) => !_.every(val, ["isCorrect", false])
          )
          .required(ERRORS.required)
          .length(4),
      })
    )
    .required(ERRORS.required)
    .min(1),
});
