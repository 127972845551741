import { HttpClient } from "../utils/httpClient";

const PATH = {
  companyWithModules: "/admin/analytics/companies-with-modules",
  analytics: "/admin/analytics/",
};

const GetCompaniesWithModules = (start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.companyWithModules)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetAnalytics = (params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.analytics, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

export const AdminAnalyticsService = {
  GetCompaniesWithModules,
  GetAnalytics,
};
