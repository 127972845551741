import axios from "axios";
import { HttpClient } from "../utils/httpClient";

const PATH = {
  getAll: "/admin/module",
  create: "/admin/module",
  update: "/admin/module",
  delete: "/admin/module",
  updatePublish: "/admin/module/publish",
  info: "/admin/module/info",
  uploadUrl: "/admin/module/upload-url",
  updateLectures: "/admin/module/update-lectures",
  updateQuiz: "/admin/module/update-quiz",
  getCompaniesWithAaccess: "/admin/module/companies-with-access",
  getUsersWithAaccess: "/admin/module/users-with-access",
  getCompaniesWithoutAaccess: "/admin/module/companies-without-access",
  getUsersWithoutAaccess: "/admin/module/users-without-access",
  removeAccess: "/admin/module/remove-access",
  addCompanyAccess: "/admin/module/access/company",
  addUserAccess: "/admin/module/access/user",
  getUsersProgress: "/admin/module/users-progress",
};

const GetAll = (params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(PATH.getAll, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const Create = (payload, start, callback, error, next) => {
  start();
  const headers = { "Content-Type": "multipart/form-data" };
  return HttpClient.post(PATH.create, payload, { headers })
    .then(callback)
    .catch(error)
    .finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
  start();
  const headers = { "Content-Type": "multipart/form-data" };
  return HttpClient.patch(`${PATH.update}/${id}`, payload, { headers })
    .then(callback)
    .catch(error)
    .finally(next);
};

const Delete = (id, start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.update}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const UpdatePublish = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.put(`${PATH.updatePublish}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const Info = (id, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.info}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const UploadUrl = (params, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.uploadUrl}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const UploadFileToS3 = (uploadUrl, file, progressHandler, handleError) => {
  const headers = {
    "Content-Type": file.type,
    "anonymous-request": "",
  };
  return axios
    .put(uploadUrl, file, { headers, onUploadProgress: progressHandler })
    .catch(handleError);
};

const UpdateLectures = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.updateLectures}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const UpdateQuiz = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.updateQuiz}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetCompaniesWithAaccess = (
  id,
  params = {},
  start,
  callback,
  error,
  next
) => {
  start();
  return HttpClient.get(`${PATH.getCompaniesWithAaccess}/${id}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetUsersWithAaccess = (id, params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getUsersWithAaccess}/${id}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetCompaniesWithoutAaccess = (
  id,
  params = {},
  start,
  callback,
  error,
  next
) => {
  start();
  return HttpClient.get(`${PATH.getCompaniesWithoutAaccess}/${id}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetUsersWithoutAaccess = (
  id,
  params = {},
  start,
  callback,
  error,
  next
) => {
  start();
  return HttpClient.get(`${PATH.getUsersWithoutAaccess}/${id}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const RemoveAccess = (id, params = {}, start, callback, error, next) => {
  start();
  return HttpClient.delete(`${PATH.removeAccess}/${id}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const AddCompanyAccess = (id, payload = {}, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.addCompanyAccess}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const AddUserAccess = (id, payload = {}, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.addUserAccess}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetUsersProgress = (id, params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getUsersProgress}/${id}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

export const AdminModuleService = {
  GetAll,
  Create,
  Update,
  Delete,
  UpdatePublish,
  Info,
  UploadUrl,
  UploadFileToS3,
  UpdateLectures,
  UpdateQuiz,
  GetCompaniesWithAaccess,
  GetUsersWithAaccess,
  RemoveAccess,
  GetCompaniesWithoutAaccess,
  GetUsersWithoutAaccess,
  AddCompanyAccess,
  AddUserAccess,
  GetUsersProgress,
};
