import { HttpClient } from "../utils/httpClient";

const PATH = {
  getAvailableModules: "/user/module/availabe-modules",
  getInProgressModules: "/user/module/in-progress-modules",
  getCompletedModules: "/user/module/completed-modules",
  saveModuleProgress: "/user/module/course-progress",
  updateModuleProgress: "/user/module/course-progress",
  getModuleProgress: "/user/module/course-progress",
  getQuizQuestions: "/user/module/quiz-questions",
  generateCertificate: "/user/module/generate-certificate",
  submitQuiz: "/user/module/quiz-questions",
  quizAttempt: "/user/module/quiz-attempt",
  info: "/user/module/info",
};

const CreateModuleProgress = (id, start, callback, error, next) => {
  start();
  return HttpClient.post(`${PATH.saveModuleProgress}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const UpdateModuleProgress = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.patch(`${PATH.updateModuleProgress}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetModuleProgress = (id, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getModuleProgress}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetModuleInfo = (id, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.info}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetAvailableModules = (params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getAvailableModules}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetInProgressModules = (params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getInProgressModules}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetCompletedModules = (params = {}, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getCompletedModules}`, { params })
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetQuizQuestions = (id, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.getQuizQuestions}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const SubmitQuiz = (id, payload, start, callback, error, next) => {
  start();
  return HttpClient.post(`${PATH.getQuizQuestions}/${id}`, payload)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GetLastAttemptQuizresult = (id, start, callback, error, next) => {
  start();
  return HttpClient.get(`${PATH.quizAttempt}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

const GenerateCertificate = (id, start, callback, error, next) => {
  start();
  return HttpClient.post(`${PATH.generateCertificate}/${id}`)
    .then(callback)
    .catch(error)
    .finally(next);
};

export const UserModuleService = {
  CreateModuleProgress,
  GetModuleProgress,
  GetModuleInfo,
  GetAvailableModules,
  GetInProgressModules,
  GetCompletedModules,
  UpdateModuleProgress,
  GetQuizQuestions,
  SubmitQuiz,
  GetLastAttemptQuizresult,
  GenerateCertificate,
};
