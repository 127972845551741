import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import Typography from "../../../components/Typography/Typography";
import { useLoader } from "../../../hooks/use-loader.hook";
import FileUpload from "../../../components/Inputs/FileUpload";
import classes from "./index.module.css";
import { Form, Formik } from "formik";
import * as XLSX from "xlsx";
import Dropdown from "../../../components/Inputs/Dropdown/indexNoState";
import { AdminUserService } from "../../../services/userService";
import { toast } from "react-toastify";
import { UisTimesCircle } from "@iconscout/react-unicons-solid";
import { DownloadAsExcel } from "../../../utils/xlsx";

const getCompanyOptions = (companies) => {
  const options = [];
  companies.forEach((comp) =>
    options.push({ label: comp.name, value: comp.id })
  );
  return options;
};

const resultInitialState = {
  status: false,
  result: false,
  failedCount: 0,
  successCount: 0,
  failedTry: [],
};

const BulkImportUsers = ({ companies }) => {
  const companyOptions = getCompanyOptions(companies);
  const [startLoader, stopLoader] = useLoader();
  const [users, setUsers] = useState([]);
  const [fileError, setFileError] = useState("");
  const [company, setComapny] = useState({});
  const [resultStatus, setResultStatus] = useState(resultInitialState);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const isFileValid = /(xlsx|csv)$/.test(file?.name?.slice(-4));
    if (isFileValid) {
      setFileError("");
      const buffer = await file.arrayBuffer();
      const workbook = XLSX.readFile(buffer, { type: "binary" });
      const sheetNames = workbook.SheetNames;
      let usersList = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNames[0]]);
      if (usersList?.length) {
        usersList = usersList.map((usr, index) => ({
          ...usr,
          countryCode: `+${usr.countryCode}`,
          sno: index + 1,
        }));
        setUsers(usersList);
      }
    } else {
      setFileError("Please select a valid xlsx or csv file");
    }
  };

  const handleSubmit = () => {
    if (users.length && company.value) {
      const payload = {
        companyId: company.value,
        users,
      };
      AdminUserService.CreateMany(
        payload,
        () => startLoader("createMany"),
        handleSuccess,
        handleError,
        () => stopLoader("createMany")
      );
    } else {
      toast.error("No users to be saved");
    }
  };

  const handleSuccess = ({ data }) => {
    const message = `Created ${data.data?.successCount} and Failed ${data.data?.failedCount}`;
    const result = {
      status: true,
      result: data.data.failedCount === 0,
      failedCount: data.data?.failedCount,
      successCount: data.data?.successCount,
      failedTry: data.data?.failedTry,
    };
    setResultStatus(result);
    if (data.data?.failedCount) {
      toast.error(message);
    } else {
      toast.success(message);
    }
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  const handleRemoveResult = () => {
    setResultStatus(resultInitialState);
  };

  const handleDownloadFailedReport = () => {
    const report = resultStatus.failedTry.map((f) => ({
      a: f.email,
      b: f.message,
    }));
    DownloadAsExcel(report, "Failed-Report", ["Email", "Reason"]);
  };

  return (
    <div className="rounded-content-area">
      <div className="content-header">
        <div>
          <Typography content={`Bulk Import Users`} />
        </div>
      </div>
      <div className={classes.BulkImportInputContainer}>
        <Formik>
          <Form>
            <div className="input-container">
              <div className="field-container-full-width">
                {/* <label htmlFor="thumbnail">Thumbnail</label> */}
                <FileUpload
                  name="thumbnail"
                  accept="xlsx/*"
                  onChange={handleFileChange}
                  id="thumbnail"
                />
                {fileError && <div className="error-msg">{fileError}</div>}
              </div>
            </div>
          </Form>
        </Formik>
      </div>
      {resultStatus.status && (
        <div
          className={
            resultStatus.result
              ? classes.BulkSuccessContainer
              : classes.BulkFailedContainer
          }
        >
          <div className={classes.BulkResultInfo}>
            <label>Successfully Inserted: {resultStatus.successCount}</label>
            <label>Failed To Inserted: {resultStatus.failedCount}</label>
            {!resultStatus.result && (
              <div className="d-flex">
                <button
                  className={`btn-primary ${classes.Button}`}
                  onClick={handleDownloadFailedReport}
                >
                  Download Failed Report
                </button>
              </div>
            )}
          </div>
          <div
            onClick={handleRemoveResult}
            className={classes.BulkResultCloseIcon}
          >
            <UisTimesCircle />
          </div>
        </div>
      )}
      <div className="content-header">
        <div>
          <div className="table-count">
            Total Count :
            <span>
              <Typography size="14" content={users.length} />
            </span>
          </div>
          <div>
            <Dropdown
              options={companyOptions}
              defaultValue={companyOptions[0]}
              onChange={setComapny}
            />
          </div>
        </div>
        <div>
          <button className="btn-primary" type="submit" onClick={handleSubmit}>
            Bulk Import
          </button>
        </div>
      </div>
      <Table
        data={users}
        head={[
          "S.No.",
          "Name",
          "Email",
          "Country Code",
          "Phone",
          "License ID",
          "Password",
        ]}
        keys={[
          "sno",
          "name",
          "email",
          "countryCode",
          "phone",
          "licenseId",
          "password",
        ]}
      />
    </div>
  );
};

export default BulkImportUsers;
