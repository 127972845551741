import React, { useEffect, useState } from "react";
import TabsComponent from "../../../../components/Tabs/Tabs";
import Table from "../../../../components/Table/Table";
import { useLoader } from "../../../../hooks";
import { AdminModuleService } from "../../../../services/moduleService";
import { UilUserMinus } from "@iconscout/react-unicons";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/DeleteModal";
import MultiSelectDropdown from "../../../../components/Inputs/MultiSelectDropdown";
import { Form, Formik } from "formik";
import { EnableCompanyValidation, EnableUsersValidation } from "../../../../validations/Admin/Module";

const CompanyAccessComponent = ({ moduleId }) => {
  const [startLoader, stopLoader] = useLoader();
  const [companies, setCompanies] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    status: false,
    id: null,
  });

  useEffect(() => {
    getData();
    getCompanyOptions();
  }, []);

  const getCompanyOptions = () => {
    AdminModuleService.GetCompaniesWithoutAaccess(
      moduleId,
      {},
      () => startLoader("getCompanyWithoutAccess"),
      handleWithoutAccessSuccess,
      handleError,
      () => stopLoader("getCompanyWithoutAccess")
    );
  };

  const handleWithoutAccessSuccess = ({ data }) => {
    if (Array.isArray(data.data)) {
      const processed = data.data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setCompanyOptions(processed);
    }
  };

  const getData = () => {
    AdminModuleService.GetCompaniesWithAaccess(
      moduleId,
      {},
      () => startLoader("getCompanyAccess"),
      handleSuccess,
      handleError,
      () => stopLoader("getCompanyAccess")
    );
  };

  const handleSuccess = ({ data }) => {
    if (Array.isArray(data.data)) {
      const processed = data.data.map((item) => ({
        ...item,
        action: (
          <div className="action-container">
            <UilUserMinus
              size={"1.2vw"}
              style={{ color: "var(--color-primary)" }}
              onClick={() => setDeleteModal({ status: true, id: item.id })}
            />
          </div>
        ),
      }));
      setCompanies(processed);
    }
  };

  const handleRemoveAccess = (id) => {
    AdminModuleService.RemoveAccess(
      moduleId,
      { companyId: id },
      () => startLoader("removeCompanyAccess"),
      handleRemoveSuccess,
      handleError,
      () => stopLoader("removeCompanyAccess")
    );
  };

  const handleRemoveSuccess = () => {
    toast.success("Access Removed!");
    getData();
  };

  const handleSubmit = (values) => {
    const payload = { companyIds: values.companyIds.map((c) => c.value) };
    AdminModuleService.AddCompanyAccess(
      moduleId,
      payload,
      () => startLoader("addCompanyAccess"),
      handleAddSuccess,
      handleError,
      () => stopLoader("addCompanyAccess")
    );
  };

  const handleAddSuccess = () => {
    toast.success("Access Added!");
    getData();
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={{ companyIds: [] }}
          onSubmit={handleSubmit}
          validationSchema={EnableCompanyValidation}
        >
          <Form>
            <MultiSelectDropdown name="companyIds" options={companyOptions} />
            <button className="btn-primary" type={"submit"}>
              Submit
            </button>
          </Form>
        </Formik>
      </div>
      <Table
        data={companies}
        head={["Name", "Action"]}
        keys={["name", "action"]}
      ></Table>
      <DeleteModal
        opendeleteModal={deleteModal.status}
        setOpenDeleteModal={(status) => setDeleteModal({ status, id: null })}
        deletefunction={handleRemoveAccess}
        functionParam={deleteModal.id}
      ></DeleteModal>
    </div>
  );
};

const UserAccessComponent = ({ moduleId }) => {
  const [startLoader, stopLoader] = useLoader();
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    status: false,
    id: null,
  });

  useEffect(() => {
    getData();
    getUserOptions()
  }, []);

  const getUserOptions = () => {
    AdminModuleService.GetUsersWithoutAaccess(
      moduleId,
      {},
      () => startLoader("getUsersWithoutAccess"),
      handleWithoutAccessSuccess,
      handleError,
      () => stopLoader("getUsersWithoutAccess")
    );
  };

  const handleWithoutAccessSuccess = ({ data }) => {
    if (Array.isArray(data.data)) {
      const processed = data.data.map((item) => ({
        label: item.email,
        value: item.id,
      }));
      setUserOptions(processed);
    }
  };

  const getData = () => {
    AdminModuleService.GetUsersWithAaccess(
      moduleId,
      {},
      () => startLoader("getUserAccess"),
      handleSuccess,
      handleError,
      () => stopLoader("getUserAccess")
    );
  };

  const handleSuccess = ({ data }) => {
    if (Array.isArray(data.data)) {
      const processed = data.data.map((item) => ({
        ...item,
        action: (
          <div className="action-container">
            <UilUserMinus
              size={"1.2vw"}
              style={{ color: "var(--color-primary)" }}
              onClick={() => setDeleteModal({ status: true, id: item.id })}
            />
          </div>
        ),
      }));
      setUsers(processed);
    }
  };

  const handleRemoveAccess = (id) => {
    AdminModuleService.RemoveAccess(
      moduleId,
      { userId: id },
      () => startLoader("removeUserAccess"),
      handleRemoveSuccess,
      handleError,
      () => stopLoader("removeUserAccess")
    );
  };

  const handleRemoveSuccess = () => {
    toast.success("Access Removed!");
    getData();
  };

  const handleSubmit = (values) => {
    const payload = { userIds: values.userIds.map((c) => c.value) };
    AdminModuleService.AddUserAccess(
      moduleId,
      payload,
      () => startLoader("addUserAccess"),
      handleAddSuccess,
      handleError,
      () => stopLoader("addUserAccess")
    );
  };

  const handleAddSuccess = () => {
    toast.success("Access Added!");
    getData();
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={{ userIds: [] }}
          onSubmit={handleSubmit}
          validationSchema={EnableUsersValidation}
        >
          <Form>
            <MultiSelectDropdown name="userIds" options={userOptions} />
            <button className="btn-primary" type={"submit"}>
              Submit
            </button>
          </Form>
        </Formik>
      </div>
      <Table
        data={users}
        head={["Name", "Email", "Action"]}
        keys={["name", "email", "action"]}
      ></Table>
      <DeleteModal
        opendeleteModal={deleteModal.status}
        setOpenDeleteModal={(status) => setDeleteModal({ status, id: null })}
        deletefunction={handleRemoveAccess}
        functionParam={deleteModal.id}
      ></DeleteModal>
    </div>
  );
};

const AccessModule = ({ moduleId }) => {
  const tabs = [
    {
      name: "Company Access",
      component: <CompanyAccessComponent moduleId={moduleId} />,
    },
    {
      name: "User Access",
      component: <UserAccessComponent moduleId={moduleId} />,
    },
  ];

  return (
    <div>
      <TabsComponent tabs={tabs} />
    </div>
  );
};

export default AccessModule;
