import React, { useEffect, useState } from "react";
import Table from "../../../../components/Table/Table";
import { useLoader } from "../../../../hooks";
import { toast } from "react-toastify";
import Typography from "../../../../components/Typography/Typography";
import moment from "moment";
import classes from "../index.module.css";

const QuizAttemptsModule = ({ moduleId, attempts = [] }) => {
  const [startLoader, stopLoader] = useLoader();
  const [data, setData] = useState([]);

  useEffect(() => {
    const processed = attempts.map((item, index) => ({
      ...item,
      sno: index + 1,
      participatedAt: moment(item.timestamp).format(),
      hasPassed: item.isCompleted ? "Yes" : "No",
      result: `${item.totalCorrect}/${item.totalQuestions} Correct`,
    }));
    setData(processed);
  }, [JSON.stringify(attempts)]);

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  return (
    <div className={classes.CreateModal}>
      <Typography content={"Quiz Attempts Data"} />
      <Table
        data={data}
        head={["S.No", "Participated At", "Has Passed", "Result"]}
        keys={["sno", "participatedAt", "hasPassed", "result"]}
      />
    </div>
  );
};

export default QuizAttemptsModule;
