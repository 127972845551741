import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { useState } from "react";
import Typography from "../../../components/Typography/Typography";
import { useLoader } from "../../../hooks";
import { AdminModuleService } from "../../../services/moduleService";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { EditQuizValidation } from "../../../validations/Admin/Module";
import { Input } from "../../../components/Inputs";
import classes from "./index.module.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import CreateButton from "../../../components/Buttons/CreateButton";
import { UilTrash } from "@iconscout/react-unicons";
import Checkbox from "../../../components/Inputs/Checkbox/Checkbox";

const defaultQuestionTemplate = {
  question: "",
  options: [
    {
      answer: "",
      isCorrect: false,
    },
    {
      answer: "",
      isCorrect: false,
    },
    {
      answer: "",
      isCorrect: false,
    },
    {
      answer: "",
      isCorrect: false,
    },
  ],
};

const getInitialValues = (editData = {}) => {
  const values = {
    title: "",
    questions: [defaultQuestionTemplate],
  };
  if (editData.title) values.title = editData.title;
  if (editData.questions?.length) values.questions = editData.questions;
  console.log(values);
  return values;
};

const EditQuiz = ({ moduleId }) => {
  const [startLoader, stopLoader] = useLoader();
  const [refresh, setRefresh] = useState(null);
  const [moduleInfo, setModuleInfo] = useState({});
  const [initialValues, setInitialValues] = useState(getInitialValues());

  useEffect(() => {
    getModuleInfo();
  }, [refresh]);

  const getModuleInfo = () => {
    if (moduleId) {
      AdminModuleService.Info(
        moduleId,
        () => startLoader("getAll"),
        handleSuccess,
        handleError,
        () => stopLoader("getAll")
      );
    }
  };

  const handleSuccess = ({ data }) => {
    if (data.data) {
      const { quiz = {} } = data.data;
      setModuleInfo(data.data);
      setInitialValues(getInitialValues(quiz));
    }
  };

  const handleSubmit = (values) => {
    if (moduleId) {
      AdminModuleService.UpdateQuiz(
        moduleId,
        values,
        () => startLoader("updateQuiz"),
        handleUpdateSuccess,
        handleError,
        () => stopLoader("updateQuiz")
      );
    }
  };

  const handleUpdateSuccess = () => {
    toast.success("Quiz Updated");
    setRefresh(Date.now());
  };

  const handleError = (err) => {
    if (err && err.response) toast.error(err.response.data.message);
  };

  return (
    <div className="rounded-content-area">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={EditQuizValidation}
      >
        {({ errors }) => (
          <Form>
            <div className="content-header">
              <Typography content={"Edit Quiz"} />
              <div>
                <button className="btn-primary" type="submit">
                  Save Changes
                </button>
              </div>
            </div>
            <div className="input-container">
              <div className="field-container-full-width">
                <label htmlFor="title">Title</label>
                <Input name={`title`} />
              </div>
            </div>
            <div className={classes.QuizQTitle}>
              <Typography content={"Quiz questions"} size={14} />
            </div>
            <Accordion
              className={classes.AccordContainer}
              allowMultipleExpanded
              allowZeroExpanded
            >
              <FieldArray name="questions">
                {({ push, remove, form }) => {
                  const { questions: vquestions = [] } = form.values;
                  return (
                    <div>
                      <div className={classes.LecturesContainer}>
                        {vquestions.map((item, index) => (
                          <AccordionItem className={classes.AccordItem}>
                            <AccordionItemHeading>
                              <AccordionItemButton
                                className={classes.AccordButton}
                              >
                                <div>
                                  {index + 1}.{" "}
                                  {item.question
                                    ? item.question
                                    : "-- No title added --"}
                                </div>
                                <div className="action-container">
                                  {errors?.questions?.[index] && (
                                    <div className={classes.ErrorPreview}>
                                      ERROR
                                    </div>
                                  )}
                                  <UilTrash
                                    size={"1.5vw"}
                                    style={{ color: "#e74c3c" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      remove(index);
                                    }}
                                  />
                                </div>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <div className={classes.QuizQQWrapper}>
                                <div className="input-container">
                                  <div className="field-container-full-width">
                                    <label htmlFor="question">Question</label>
                                    <Input
                                      name={`questions.${index}.question`}
                                    />
                                  </div>
                                </div>
                              </div>
                              <FieldArray name={`questions.${index}.options`}>
                                {() => {
                                  const { options = [] } = item;
                                  return options.map((sItem, sIndex) => (
                                    <div className="input-container">
                                      <div className="field-container">
                                        <label>Is Correct</label>
                                        <Checkbox
                                          name={`questions.${index}.options.${sIndex}.isCorrect`}
                                          defaultValue={sItem?.isCorrect}
                                        />
                                      </div>

                                      <div className="field-container-grow-2">
                                        <label htmlFor="question">
                                          Answer {sIndex + 1}
                                        </label>
                                        <Input
                                          name={`questions.${index}.options.${sIndex}.answer`}
                                        />
                                      </div>
                                    </div>
                                  ));
                                }}
                              </FieldArray>
                              {errors?.questions?.[index]?.options &&
                                typeof errors?.questions?.[index]?.options ===
                                  "string" && (
                                  <div className="error-msg">
                                    {errors?.questions?.[index]?.options}
                                  </div>
                                )}
                            </AccordionItemPanel>
                          </AccordionItem>
                        ))}
                      </div>
                      <div className={classes.AccordActionButton}>
                        <CreateButton
                          size={"medium"}
                          text="Add Question"
                          onClick={() => push(defaultQuestionTemplate)}
                        />
                      </div>
                    </div>
                  );
                }}
              </FieldArray>
            </Accordion>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditQuiz;
