import { SET_NAVBAR_STATE } from "../../constants";

export const NavBarReducer = (state, action) => {
  switch (action.type) {
    case SET_NAVBAR_STATE:
      return {
        ...state,
        showDefault: action.payload.showDefault,
        title: action.payload.title,
      };
    default:
      return state;
  }
};
