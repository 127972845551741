import React from "react";
import TopBanner from "./TopBanner";
import AvailableCourses from "./AvailableCourses";
import InProgressCourses from "./InProgressCourses";
import CompletedCourses from "./CompletedCoursed";

const Home = () => {
  return (
    <div className="container-fluid">
      <TopBanner />
      <div id="modules">
      <InProgressCourses />
      <AvailableCourses />
      <CompletedCourses />
      </div>
    </div>
  );
};

export default Home;
