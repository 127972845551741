import * as XLSX from 'xlsx';

export const DownloadAsExcel = (data, fileName, passedHeaders) => {
  let processedData = [...data],
    processedHeader = {};
  Object.keys(data[0]).forEach((header, i) => {
    processedHeader[header] = passedHeaders[i];
  });
  processedData.splice(0, 0, processedHeader);
  const workBook = XLSX.utils.book_new();
  const workSheet = XLSX.utils.json_to_sheet(processedData, { skipHeader: true });
  XLSX.utils.book_append_sheet(workBook, workSheet, ['sheet1']);
  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};

export const DownloadAsExcelMultipleSheet = (data, sheetNames, fileName, passedHeaders) => {
  const workBook = XLSX.utils.book_new();
  data.map((item, index) => {
    let processedData = [...item],
      processedHeader = {};
    const curHeader = passedHeaders[index]
    Object.keys(item[0]).forEach((header, i) => {
      processedHeader[header] = curHeader[i];
    });
    processedData.splice(0, 0, processedHeader);
    const workSheet = XLSX.utils.json_to_sheet(processedData, { skipHeader: true });
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetNames[index]);
  });
  XLSX.writeFile(workBook, `${fileName}.xlsx`);
};