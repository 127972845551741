import React from "react";
import classes from "./index.module.css";
import BannerImage from "../../../assets/topBanner.webp";

const TopBanner = () => {
  return (
    <div className="container">
      <div className={classes.TopBannerImageContainer}>
        <div className={classes.TopBannerTextContainer}>
          <p className={classes.TopBannerTextTitle}>
            Learn with Ariel Anytime Anywhere.
          </p>
          <p className={classes.TopBannerTextSubTitle}>
            Unlock Your Career Potential: Learn, Grow, and Land Your Dream Job
            with Our eLearning Platform!
          </p>
          <div>
            <a className={classes.TopBannerButton} href="/#modules">
              Get Started
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
