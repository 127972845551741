import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import PublicLayout from "./layouts/PublicLayout";
import { PrivateRoute } from "./routes/PrivateRoute";
import { useContext, useEffect } from "react";
import { UserService } from "./services/userService";
import { REMOVE_USER, SET_USER } from "./constants";
import { AuthContext } from "./context/AuthContext";
import { useLoader } from "./hooks";

const App = () => {
  const [startLoader, stopLoader] = useLoader();
  const { dispatch: authDispatch } = useContext(AuthContext);
  const dispatchSetUser = (payload) =>
    authDispatch({ type: SET_USER, payload });
  const removeUser = () => authDispatch({ type: REMOVE_USER });

  useEffect(() => {
    document.documentElement.style.setProperty('--color-primary', '#ec1e23')
    verifyUser();
  }, []);

  const verifyUser = () => {
    UserService.Profile(
      () => startLoader("verifyUser"),
      handleVerifySuccess,
      handleVerifyError,
      () => stopLoader("verifyUser")
    );
  };

  const handleVerifySuccess = ({ data }) => {
    if (data.success) {
      dispatchSetUser({ user: data.data });
    }
  };

  const handleVerifyError = (err) => {
    removeUser();
  };

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/auth" component={AuthLayout} />
          <PrivateRoute path="/" component={PublicLayout} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
