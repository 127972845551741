import React from "react";
import classes from "./index.module.css";
import CourseCard from "../../../components/CourseCard";
import useEmblaCarousel from "embla-carousel-react";
import { useState } from "react";
import { useEffect } from "react";
import { UserModuleService } from "../../../services/userModuleService";
import { useLoader } from "../../../hooks";
import { toast } from "react-toastify";

const CompletedCourses = () => {
  const [startLoader, stopLoader] = useLoader();
  const [modules, setModules] = useState([]);
  const [emblaRef] = useEmblaCarousel({ align: "start" });

  useEffect(() => {
    UserModuleService.GetCompletedModules(
      {},
      () => startLoader("getModuleInfo"),
      handleSuccess,
      handleError,
      () => stopLoader("getModuleInfo")
    );
  }, []);

  const handleSuccess = ({ data }) => {
    if (Array.isArray(data.data)) {
      setModules(data.data);
    }
  };

  const handleError = (err) => {
    let data = err && err.response ? err.response.data : null;
    if (data) toast.error(data.message);
    else toast.error("Internal server error!");
  };

  return (
    <>
      {modules.length ? (
        <div className={classes.SectionContainer} id="completed">
          <div className={classes.SectionHeader}>
            <div>
              <p className={classes.SectionTitle}>Completed Modules</p>
            </div>
            <div>
              {/* <button className={classes.TopBannerButton}>View All</button> */}
            </div>
          </div>
          <div className="embla" ref={emblaRef}>
            <div className="embla__container">
              {modules.map((item, index) => (
                <div className="embla__slide" key={index}>
                  <CourseCard data={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CompletedCourses;
