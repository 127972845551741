import React from "react";
import classes from "./index.module.css";
import { UserModuleService } from "../../services/userModuleService";
import { useLoader } from "../../hooks/use-loader.hook";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { CircularProgressbar, buildStyles  } from "react-circular-progressbar";

const CourseCard = ({ data }) => {
  const [startLoader, stopLoader] = useLoader();
  const history = useHistory();

  const handleModuleClick = (moduleId) => {
    UserModuleService.CreateModuleProgress(
      moduleId,
      () => startLoader("createCourseProgress"),
      handleSuccess,
      handleError,
      () => stopLoader("createCourseProgress")
    );
  };

  const handleSuccess = ({ data }) => {
    history.push(`/module/${data.data.moduleId}`);
  };

  const handleError = (err) => {
    let data = err && err.response ? err.response.data : null;
    if (data) toast.error(data.message);
    else toast.error("Internal server error!");
  };

  return (
    <div
      className={classes.ModuleWrapper}
      onClick={() => handleModuleClick(data.id)}
    >
      <div className={classes.ModuleContainer}>
        <div className={classes.ModuleImageWrapper}>
          <img
            src={data.thumbnail}
            alt="thumbnail"
            className={classes.ModuleImage}
          />
        </div>
        <div className={classes.ModuleInfo}>
          <div className={classes.ModuleTitle}>{data.title}</div>
          {data.started ? (
            <div className={classes.ModuleProgress}>
              <CircularProgressbar
                value={data.progressPercentage}
                text={`${data.progressPercentage}%`}
                styles={buildStyles({
                  textSize: '30px',
                  pathColor: `var(--color-primary)`,
                  textColor:'var(--color-primary)'
                })}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
