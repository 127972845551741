import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({ series = [], labels = [], width, height }) => {
  const options = {
    labels,
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      textAnchor:'middle'
    }
  };
  return (
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={width}
        height={height}
      />
  );
};

export default PieChart;
