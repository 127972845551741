import React, { useState } from "react";
import AllModules from "./AllModules";
import TabsComponent from "../../../components/Tabs/Tabs";
import EditLectures from "./EditLectures";
import EditQuiz from "./EditQuiz";
import UserProgress from "./UserProgress";

const Modules = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [editLecturesTab, setEditLecturesTab] = useState({
    status: false,
    moduleId: null,
  });
  const [editQuizTab, setEditQuizTab] = useState({
    status: false,
    moduleId: null,
  });
  const [userProgressTab, setUserProgressTab] = useState({
    status: false,
    moduleId: null,
    totalLectures: 0,
  });

  const handleEditLecturesTab = (status = false, moduleId) => {
    setEditLecturesTab({ status, moduleId });
    if (status) setSelectedIndex(1);
  };

  const handleEditQuizTab = (status = false, moduleId) => {
    setEditQuizTab({ status, moduleId });
    if (status) setSelectedIndex(1);
  };

  const handleUserProgressTab = (
    status = false,
    moduleId,
    totalLectures = 0
  ) => {
    setUserProgressTab({ status, moduleId, totalLectures });
    if (status) setSelectedIndex(1);
  };

  const handleChangeTab = (index) => {
    setEditLecturesTab({ status: false, moduleId: null });
    setEditQuizTab({ status: false, moduleId: null });
    setUserProgressTab({ status: false, moduleId: null });
    setSelectedIndex(index);
  };

  const tabs = [
    {
      name: "All Modules",
      component: (
        <AllModules
          handleEditLecturesTab={handleEditLecturesTab}
          handleUserProgressTab={handleUserProgressTab}
          handleEditQuizTab={handleEditQuizTab}
        />
      ),
    },
    ...(editLecturesTab.status
      ? [
          {
            name: "Edit Lectures",
            component: <EditLectures moduleId={editLecturesTab.moduleId} />,
          },
        ]
      : []),
    ...(editQuizTab.status
      ? [
          {
            name: "Edit Quiz",
            component: <EditQuiz moduleId={editQuizTab.moduleId} />,
          },
        ]
      : []),
    ...(userProgressTab.status
      ? [
          {
            name: "Users Progress",
            component: (
              <UserProgress
                moduleId={userProgressTab.moduleId}
                totalLectures={userProgressTab.totalLectures}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="react-tabs-background">
      <TabsComponent
        tabs={tabs}
        selectedIndex={selectedIndex}
        onChangeTab={handleChangeTab}
      />
    </div>
  );
};

export default Modules;
