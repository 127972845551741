import React from "react";
import classes from "./index.module.css";
import { useState } from "react";
import { UserModuleService } from "../../../services/userModuleService";
import { useParams, useHistory } from "react-router-dom";
import { useLoader } from "../../../hooks";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  UilPlay,
  UilCheckCircle,
  UilFileQuestionAlt,
  UilTimesCircle,
  UilMedal,
} from "@iconscout/react-unicons";
import { UisCheckCircle, UisTimesCircle } from "@iconscout/react-unicons-solid";
import VideoJS from "../../../components/VideoPlayer";
import { useRef } from "react";
import { isArray } from "lodash";
import CheckboxNoState from "../../../components/Inputs/Checkbox/CheckboxNoState";
import { NavBarContext } from "../../../context/NavBarContext";
import { SET_NAVBAR_STATE } from "../../../constants";
import { useContext } from "react";

const videoPlayerOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  loop: false,
};

const Module = () => {
  const { id: moduleId } = useParams();
  const history = useHistory();
  const { dispatch: NavBarDispatch } = useContext(NavBarContext);
  const [startLoader, stopLoader] = useLoader();
  const [moduleInfo, setModuleInfo] = useState({});
  const [lecture, setLecture] = useState({ id: null, videoUrl: null });
  const [completedLectures, setCompletedLectures] = useState([]);
  const [videEndEffect, setVideEndEffect] = useState(null);
  const [showQuiz, setShowQuiz] = useState({ status: false, stage: 0 });
  const [questions, setQuestions] = useState([]);
  const [getQuestionsLoader, setQuestionLoader] = useState(false);
  const [isQuizCompleted, setQuizCompleted] = useState(false);
  const [quizResult, setQuizResult] = useState({});
  const [quizAttemptAvailable, setQuizAttemptAvailable] = useState(false);
  const [isModuleCompleted, setModuleCompleted] = useState(false);
  const [certificate, setCertificate] = useState(null);

  const ref = useRef();

  useEffect(() => {
    return () => {
      NavBarDispatch({
        type: SET_NAVBAR_STATE,
        payload: { showDefault: true, title: "" },
      });
    };
  }, []);

  useEffect(() => {
    getModuleInfo();
  }, [moduleId]);

  useEffect(() => {
    if (videEndEffect) {
      handleVideoEnd();
    }
  }, [videEndEffect]);

  const getModuleInfo = () => {
    UserModuleService.GetModuleInfo(
      moduleId,
      () => startLoader("getModuleInfo"),
      handleModuleInfoSuccess,
      handleErrorAndGoHome,
      () => stopLoader("getModuleInfo")
    );
  };

  const handleModuleInfoSuccess = ({ data }) => {
    setModuleInfo(data.data);
    getModuleProgress();
    updateNavBarState(data.data.title);
  };

  const updateNavBarState = (title) => {
    NavBarDispatch({
      type: SET_NAVBAR_STATE,
      payload: { showDefault: false, title },
    });
  };

  const getModuleProgress = () => {
    UserModuleService.CreateModuleProgress(
      moduleId,
      () => {},
      handleModuleProgressSuccess,
      handleError,
      () => {}
    );
  };

  const handleModuleProgressSuccess = ({ data }) => {
    if (data.data?.lectureProgress) {
      const lectureProgress = data.data?.lectureProgress;
      const temp = [];
      Object.keys(lectureProgress).forEach((p) => {
        const progress = lectureProgress[p];
        if (progress.isCompleted) temp.push(p);
      });
      setCompletedLectures(temp);
      setQuizCompleted(data.data?.quizProgress?.isCompleted || false);
      // if (data.data?.quizProgress?.attemptsData?.length)
      //   setQuizAttemptAvailable(true);
      if (data.data.isCompleted) setModuleCompleted(true);
      if (data.data.certificate) setCertificate(data.data.certificate);
    }
  };

  const handleVideoEnd = () => {
    UserModuleService.UpdateModuleProgress(
      moduleId,
      { lectureId: lecture.id },
      () => {},
      handleUpdateProgressSuccess,
      handleError,
      () => {}
    );
  };

  const handleUpdateProgressSuccess = () => {
    getModuleProgress();
    playNext();
  };

  const playNext = () => {
    const videoLectures = moduleInfo.videoLectures || [];
    const curVideoIndex = videoLectures.findIndex((v) => v.id === lecture.id);
    if (curVideoIndex !== -1 && videoLectures[curVideoIndex + 1]) {
      const nextVideo = videoLectures[curVideoIndex + 1];
      setLecture({ id: nextVideo.id, videoUrl: nextVideo.videoUrl });
    }
  };

  const getQuizQuestions = () => {
    UserModuleService.GetQuizQuestions(
      moduleId,
      () => setQuestionLoader(true),
      handleQuizQuestionsSuccess,
      handleError,
      () => setQuestionLoader(false)
    );
  };

  const handleQuizQuestionsSuccess = ({ data }) => {
    if (isArray(data.data)) setQuestions(data.data);
    setShowQuiz({ ...showQuiz, stage: 1 });
  };

  const handleSubmitQuiz = () => {
    const answersArray = questions.map((q) => q.answers);
    UserModuleService.SubmitQuiz(
      moduleId,
      { answersArray },
      () => startLoader("submitQuuiz"),
      handleSubitQuizSuccess,
      handleError,
      () => stopLoader("submitQuuiz")
    );
  };

  const handleSubitQuizSuccess = ({ data }) => {
    setQuizResult(data.data);
    setShowQuiz({ ...showQuiz, stage: -1 });
    getModuleProgress();
  };

  const getLastAttemptedQuizResults = () => {
    UserModuleService.GetLastAttemptQuizresult(
      moduleId,
      () => setQuestionLoader(true),
      lastAttemptedQuizResultSuccess,
      handleError,
      () => setQuestionLoader(false)
    );
  };

  const lastAttemptedQuizResultSuccess = ({ data }) => {
    setQuizResult(data.data);
    setShowQuiz({ ...showQuiz, stage: -1 });
  };

  const generateCertificate = () => [
    UserModuleService.GenerateCertificate(
      moduleId,
      () => startLoader("generateCertificate"),
      generateCertificateSuccess,
      handleError,
      () => stopLoader("generateCertificate")
    ),
  ];

  const generateCertificateSuccess = () => {
    toast.success("Certificate generated!");
    getModuleProgress();
  };

  const handleError = (err) => {
    let data = err && err.response ? err.response.data : null;
    if (data) toast.error(data.message);
    else toast.error("Internal server error!");
  };

  const handleErrorAndGoHome = (err) => {
    let data = err && err.response ? err.response.data : null;
    if (data) toast.error(data.message);
    else toast.error("Internal server error!");
    history.push("/");
  };

  const handleSelectOption = (value, optionIndex, stage) => {
    const newQuestions = [...questions];
    if (value) {
      newQuestions[stage - 1].answers.push(optionIndex);
    } else {
      newQuestions[stage - 1].answers.splice(
        newQuestions[stage - 1].answers.findIndex((el) => el === optionIndex),
        1
      );
    }
    setQuestions(newQuestions);
  };

  return (
    <div className="rounded-content-area" ref={ref}>
      {showQuiz.status ? (
        <>
          <div className={classes.QuizQuestionWrapper}>
            {showQuiz.stage === 0 ? (
              <div>
                <div className={classes.QuizTitle}>{moduleInfo.quiz.title}</div>
                <div className={classes.QuizSubTitle}>
                  {moduleInfo.quiz.totalQuestions} Questions
                  {quizAttemptAvailable && (
                    <span
                      className={classes.QuizTtleIconF}
                      onClick={getLastAttemptedQuizResults}
                    >
                      , View last attempt result
                    </span>
                  )}
                </div>
                <div className={classes.ButtonWrapper}>
                  <button
                    className={classes.ButtonPrimary}
                    onClick={getQuizQuestions}
                  >
                    Start Quiz
                  </button>
                  <button
                    className={classes.ButtonSecondary}
                    onClick={() => setShowQuiz({ status: false, stage: 0 })}
                  >
                    Cancel
                  </button>
                </div>
                {getQuestionsLoader && (
                  <div className={classes.QuizTtleIconF}>Loading...</div>
                )}
              </div>
            ) : showQuiz.stage === -1 ? (
              <>
                <div className={classes.QuizStartWrapper}>
                  <div
                    className={
                      quizResult.isCompleted
                        ? classes.QuizResultPassedHeader
                        : classes.QuizResultFailedHeader
                    }
                  >
                    <>
                      {quizResult.isCompleted ? (
                        <>
                          <div>
                            <UisCheckCircle className={classes.QuizTtleIconP} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <UisTimesCircle className={classes.QuizTtleIconF} />
                          </div>
                        </>
                      )}
                    </>
                    <div>
                      <div className={classes.QuizResultTitle}>
                        {quizResult.isCompleted ? (
                          <>Congratulations! You passed!</>
                        ) : (
                          <>Sorry, you did not pass. Please try again.</>
                        )}
                      </div>
                      <div className={classes.QuizResultSubTitle}>
                        <div>
                          Result: {quizResult.totalCorrect}/
                          {quizResult.totalQuestions} Correct.
                        </div>
                        {!quizResult.isCompleted && (
                          <div>Review the lectures and try again!</div>
                        )}
                      </div>
                      <div className={classes.ResultHeaderBtnContainer}>
                        <button
                          className={classes.ButtonPrimary}
                          onClick={() => {
                            setShowQuiz({
                              status: false,
                              stage: 0,
                            });
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                  {quizResult.quizResult?.map((r, i) => (
                    <>
                      <div className={classes.QuizQuestion}>
                        {i + 1}. {r.question}
                      </div>
                      {r.options.map((option, index) => (
                        <div className={classes.QuizQuestionOption}>
                          <CheckboxNoState
                            defaultValue={r.answered?.includes(index)}
                          />
                          {option}
                        </div>
                      ))}
                      {r.passed ? (
                        <div className={classes.CorrectChip}>
                          <UilCheckCircle />
                          Correct
                        </div>
                      ) : (
                        <div className={classes.IncorrectChip}>
                          <UilTimesCircle />
                          Incorrect
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </>
            ) : (
              <div className={classes.QuizStartWrapper}>
                <div className={classes.QuizQuestion}>
                  Question {showQuiz.stage}: <br></br>
                  {questions[showQuiz.stage - 1].question}
                </div>
                <div>
                  {questions[showQuiz.stage - 1].options.map(
                    (option, index) => (
                      <div
                        className={classes.QuizQuestionOption}
                        onClick={() =>
                          handleSelectOption(
                            !questions[showQuiz.stage - 1].answers?.includes(
                              index
                            ),
                            index,
                            showQuiz.stage
                          )
                        }
                      >
                        <CheckboxNoState
                          defaultValue={questions[
                            showQuiz.stage - 1
                          ].answers?.includes(index)}
                        />
                        {option}
                      </div>
                    )
                  )}
                </div>
                <div className={classes.ButtonWrapper}>
                  {showQuiz.stage !== 1 ? (
                    <button
                      className={classes.ButtonSecondary}
                      onClick={() => {
                        setShowQuiz({ ...showQuiz, stage: showQuiz.stage - 1 });
                      }}
                    >
                      Prev
                    </button>
                  ) : null}
                  {showQuiz.stage !== questions.length ? (
                    <button
                      className={classes.ButtonPrimary}
                      onClick={() => {
                        setShowQuiz({ ...showQuiz, stage: showQuiz.stage + 1 });
                      }}
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      className={classes.ButtonPrimary}
                      onClick={handleSubmitQuiz}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={classes.ModuleMain}>
          <div className={classes.ModuleFirst}>
            <div className={classes.FisrtWrapper}>
              {lecture.videoUrl ? (
                <div className={classes.ModuleImageContainer}>
                  <VideoJS
                    options={{
                      ...videoPlayerOptions,
                      sources: [{ src: lecture.videoUrl, type: "video/mp4" }],
                    }}
                    onVideoEnd={() => setVideEndEffect(Date.now())}
                  />
                </div>
              ) : (
                <div className={classes.ModuleImageContainer}>
                  {moduleInfo.thumbnail && (
                    <img
                      src={moduleInfo.thumbnail}
                      className={classes.ModuleImage}
                      alt="thumbnail"
                    />
                  )}
                </div>
              )}

              <div className={classes.ModuleInfo}>
                {/* <div className={classes.ModuleTitle}>{moduleInfo.title}</div> */}
                <div className={classes.ModuleDescription}>
                  {moduleInfo.description}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.ModuleSecond}>
            <div className={classes.LecturesContainer}>
              {moduleInfo.videoLectures?.map((lecture, index) => (
                <div
                  className={
                    classes.LectureWrapper +
                    ` ${
                      index !== moduleInfo.videoLectures.length - 1
                        ? classes.BorderBottom
                        : ""
                    }`
                  }
                  onClick={(e) => {
                    setLecture({ id: lecture.id, videoUrl: lecture.videoUrl });
                    e.stopPropagation();
                  }}
                >
                  <div>
                    {index + 1}. {lecture.title}
                  </div>
                  <div className={classes.LecturesStatusIcon}>
                    {completedLectures.includes(lecture.id) ? (
                      <UilCheckCircle className={classes.ActionPlay} />
                    ) : (
                      <UilPlay className={classes.ActionPlay} />
                    )}
                  </div>
                </div>
              ))}
            </div>
            {moduleInfo.quiz?.id && (
              <div
                className={classes.QuizLectureContainer}
                onClick={(e) => {
                  if (
                    moduleInfo.videoLectures?.length ===
                    completedLectures.length
                  ) {
                    setShowQuiz({ status: true, stage: 0 });
                  } else {
                    toast.error("Complete all video lectures first!");
                  }
                  e.stopPropagation();
                }}
              >
                <div className={classes.LectureWrapper}>
                  <div>Quiz. {moduleInfo.quiz.title}</div>
                  <div className={classes.LecturesStatusIcon}>
                    {isQuizCompleted ? (
                      <UilCheckCircle className={classes.ActionPlay} />
                    ) : (
                      <UilFileQuestionAlt className={classes.ActionPlay} />
                    )}
                  </div>
                </div>
              </div>
            )}
            {isModuleCompleted && (
              <div
                className={classes.QuizLectureContainer}
                onClick={(e) => {
                  e.stopPropagation();
                  certificate
                    ? toast.success('Certificate already sent to your company')
                    : generateCertificate();
                }}
              >
                <div className={classes.LectureWrapper}>
                  {certificate ? (
                    <div>Certificate generated</div>
                  ) : (
                    <div>Generate certificate</div>
                  )}
                  <div className={classes.LecturesStatusIcon}>
                    <UilMedal className={classes.ActionPlay} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Module;
